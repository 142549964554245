import ProTable, { ActionType, ColumnsState } from '@ant-design/pro-table';
import {
  Button,
  DatePicker,
  Dropdown,
  Menu,
  Modal,
  Select,
  SelectProps,
  Spin,
  Tag,
  Tooltip,
  UploadProps,
} from 'antd';
import {
  useContext,
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService, {
  IFileData,
} from '../../services/graphql/GraphqlService';
import { Tools, ABM, Authorization } from '../../shared';
import { ContextApp } from '../../contexts/ContextApp';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import './Receipt.less';
import {
  ICurrencyType,
  IReceiptHeader,
  IReceiptLetter,
  IReceiptType,
  IStatus,
} from '../../interfaces/Receipt';
import moment from 'moment';
import { FORMAT_DATE_TIME_3 } from '../../shared/MomentJS';
import { EnumsValues, TenantStatus } from '../../enums/EnumsValues';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { tooltipTrigger } from '../../shared/antdUtils';
import { IBaseFile } from '../../interfaces/defaultSchema';
import { CustomMessage } from '../../hooks';
import { ReprocessCard } from '../../components/common/ReprocessCard/ReprocessCard';
import { FileAlertOutlined } from '../../components/Icons/FileAlertOutlined';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import useNotification from '../../hooks/useNotification';
import { IInvitation, ITenant } from '../../interfaces/Tenant';
import { ExportSendEmail } from './components/ExportSendEmail/ExportSendEmail';
import { UploadDragAndDrop } from '../../components/common/UploadDragAndDrop/UploadDragAndDrop';
import { RcFile, UploadFile } from 'antd/lib/upload';
import { IAppSetting } from '../../interfaces/AppSetting';
import { AvailableQuotaCard } from '../../components/common/AvailableQuotaCard/AvailableQuotaCard';
import useNotificationCards from '../../hooks/useNotificationCards';
import { IGrouper } from '../../interfaces/Grouper';
import { PageLoading } from '@ant-design/pro-layout';
import { notificationContext } from '../../contexts/NotificationContext';
import { FormInstance } from 'antd/es/form';
import ActionMenu, {
  ExtendedMenuItemType,
} from './components/ActionMenu/ActionMenu';
import ModalViewReceipt from './components/ModalViewReceipt/ModalViewReceipt';
import ToolbarFilterOrder from '../../components/common/ToolbarFilterOrder/ToolbarFilterOrder';
import { ITenantSettingCurrentValue } from '../../interfaces/TenantSetting';
import { IIntegration } from '../../interfaces/Integration';
import { DownOutlined } from '@ant-design/icons';
import useGetElementDimensions from '../../hooks/useGetElementDimensions';

export interface IViewModalReceiptState {
  open: boolean;
  data: IReceiptHeader | undefined;
}

const LIST_SORTER = [
  'id',
  'receipt_date',
  'status',
  'receipt_type',
  'receipt_number',
  'sender',
  'receipt_letter',
];
const RANGEDAYS = 7;
const DEFAULT_MAX_PAGE_PER_DOCUMENT = '6';

const dateWithRange = moment(
  new Date().setDate(new Date().getDate() - RANGEDAYS),
);
const dateNow = moment(new Date());

export default function Receipt() {
  // states

  const {
    t,
    selectedTenantId,
    functions,
    notifications,
    getTenantsAssociatedWithUser,
    getAvailableQuota,
    availableQuota,
    tenantsAssociatedToUser,
  } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const [searchText, setSearchText] = useState('');
  const [editForm] = useState<any>({});
  const [sorter, setSorter] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useState<number[]>([]);
  const [groupIdSelected, setGroupIdSelected] = useState<number>();
  const [groupers, setGroupers] = useState<IGrouper[]>([]);
  const [selectedGrouper, setSelectedGrouper] = useState<number>();
  const { refreshNotification } = useNotification({});
  const [invitationData, setInvitationData] = useState<IInvitation>();
  const [updatedReceiptId, setUpdatedReceiptId] =
    useState<number | undefined>(undefined);
  const [updatedReceiptStatusId, setUpdatedReceiptStatusId] =
    useState<number | undefined>(undefined);
  const [receiptToHighlight, setReceiptToHighlight] =
    useState<number | undefined>(undefined);
  const [viewSendEmailModal, setViewSendEmailModal] = useState<boolean>(false);
  const [viewModalReceipt, setViewModalReceipt] =
    useState<IViewModalReceiptState>({
      open: false,
      data: undefined,
    });
  const [clearHelper, setClearHelper] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [useLastFilter, setUseLastFilter] = useState<boolean>(false);
  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );
  const [fileListState, setFileListState] = useState<any>([]);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [pulseAnimation, setPulseAnimation] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [initialFilters, setInitialFilters] = useState<{
    receipt_date_range: any;
    status: any;
  }>({
    receipt_date_range: [dateWithRange, dateNow],
    status: [],
  });

  // services and hooks
  const { Query, customRequest, Mutation, customFileRequest } =
    GraphqlService();
  const { getDate, getUserDateFormat } = MomentTimezoneService();
  const { getErrorMessage } = CustomMessage();
  const location = useLocation();
  const history = useHistory();
  const { errorCard } = useNotificationCards();
  const [reactFirstCallFinished, setReactFirstCallFinished] =
    useState<boolean>(false);

  // refs
  const selectedGrouperRef = useRef<number | undefined>(undefined);
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();
  const variables = useRef<any>({});
  const fileListRef = useRef<any>([]);
  const fileListDuplicatedRef = useRef<any>([]);
  const fileListMaxSizeRef = useRef<any>([]);
  const contador = useRef<number>(0);
  const counterProcessedMinusDuplicated = useRef<number>(0);
  const counterFileNotSupported = useRef<number>(0);
  const counterFileNotProcessed = useRef<number>(0);
  const fileLimitExceeded = useRef<boolean>(false);
  const fileQuotaExceeded = useRef<boolean>(false);
  const disabledRefresh = useRef<boolean>(false);
  const [loadDisabled, setLoadDisabled] = useState<boolean>(false);
  const [selectedIntegration, setSelectedIntegration] =
    useState<IIntegration>();
  const [loadingTenant, setLoadingTenant] = useState<boolean>(true);
  const firstLoad = useRef<boolean>(true);
  const maxPagePerDocument = useRef<string>(DEFAULT_MAX_PAGE_PER_DOCUMENT);
  const animatedButton = useRef<any>(null);

  const { width } = useGetElementDimensions('.btn-refresh');

  useLayoutEffect(() => {
    if (animatedButton.current) {
      animatedButton.current.style.width = `${width}px`;
    }
  }, [width]);

  useLayoutEffect(() => {
    const shouldUsePreviousFilters = location?.state?.keepFilter && clearHelper;

    if (location.state?.keepFilter) {
      setUseLastFilter(location.state?.keepFilter);
    }
    if (location.state?.updatedReceiptId) {
      setUpdatedReceiptId(location.state?.updatedReceiptId);
    }
    if (location.state?.receiptStatusID) {
      setUpdatedReceiptStatusId(location.state?.receiptStatusID);
    }

    if (!location?.state?.keepFilter) {
      clearSessionStorage();
    }

    const fromDateOnStorage = sessionStorage.getItem(
      EnumsValues.SessionStorageKeys.FromDateMonitorPage,
    );

    const toDateOnStorage = sessionStorage.getItem(
      EnumsValues.SessionStorageKeys.ToDateMonitorPage,
    );

    let from;
    let to;

    if (shouldUsePreviousFilters) {
      if (fromDateOnStorage && fromDateOnStorage !== 'undefined') {
        from = moment(fromDateOnStorage);
      } else {
        from = undefined;
      }

      if (toDateOnStorage && toDateOnStorage !== 'undefined') {
        to = moment(toDateOnStorage);
      } else {
        to = undefined;
      }
    } else {
      from = dateWithRange;
      to = dateNow;
    }

    const InvoiceselectedFilters = sessionStorage.getItem(
      EnumsValues.SessionStorageKeys.InvoiceselectedFilters,
    );

    if (InvoiceselectedFilters) {
      setSelectedFilters(JSON.parse(InvoiceselectedFilters));
    }

    setInitialFilters({
      status: InvoiceselectedFilters ? JSON.parse(InvoiceselectedFilters) : [],
      receipt_date_range: [from, to],
    });
  }, [location]);

  useLayoutEffect(() => {
    renderFilterText();
    const receipList = document.getElementsByClassName(
      'ant-upload-list-picture',
    );
    document.getElementById('receipts-uploaded')?.appendChild(receipList[0]);
  }, []);

  useEffect(() => {
    if (reactFirstCallFinished) {
      const grouper_id = sessionStorage.getItem(
        EnumsValues.SessionStorageKeys.InvoiceGrouperId,
      );

      handleGrouperChange(Number(grouper_id));
    }
  }, [reactFirstCallFinished]);

  useEffect(() => {
    selectedGrouperRef.current = selectedGrouper;
    if (groupIdSelected) {
      getStates();
    } else {
      getStates(true);
    }
  }, [selectedGrouper]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const invitation_uid = params.get('inv_id');
    if (invitation_uid) {
      getInvitationData(invitation_uid);
    }
  }, [location.search]);

  useEffect(() => {
    if (invitationData?.role && invitationData?.tenant) {
      const modal = Modal.confirm({
        onOk: () => handleResponseInvitation(true),
        onCancel: () => handleResponseInvitation(false),
        cancelText: t('action.reject'),
        content: (
          <span>
            {t('message.acceptInvitation', {
              roleName: invitationData.role.name,
              tenantName: invitationData.tenant.name,
            })}
          </span>
        ),
        okButtonProps: { className: 'save-btn' },
      });
      return modal.destroy;
    }
  }, [invitationData]);

  useEffect(() => {
    setReactFirstCallFinished(true);
    if (reactFirstCallFinished) {
      getStates(true);
      getGroupers();
      getLimitMaxSizeFileSetting();
      getPageLimitNumber();
    }
  }, [reactFirstCallFinished]);

  useEffect(() => {
    setClearHelper(false);
    actionRef.current?.reloadAndRest && actionRef.current.reloadAndRest();
  }, [selectedGrouper]);

  useEffect(() => {
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  }, [initialFilters]);

  /**
   * Se configura por cada ABM diferente
   */
  const INPUT_SEARCH_PLACEHOLDER = `${t('ReceiptTray.searchByFields')}`;

  // methods

  const clearSessionStorage = () => {
    sessionStorage.removeItem(
      EnumsValues.SessionStorageKeys.FromDateMonitorPage,
    );
    sessionStorage.removeItem(EnumsValues.SessionStorageKeys.ToDateMonitorPage);
    sessionStorage.removeItem(EnumsValues.SessionStorageKeys.InvoiceGrouperId);
    sessionStorage.removeItem(
      EnumsValues.SessionStorageKeys.InvoiceselectedFilters,
    );
  };

  const refreshTableClick = async (activePulse: boolean) => {
    disabledRefresh.current = true;
    setPulseAnimation(activePulse);
    await actionRef.current?.reload();
    disabledRefresh.current = false;
  };
  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const request = async (params: any) => {
    try {
      const DATE_RANGE = 'receipt_date_range';
      const RECEIPT_DATE_FROM = 'receipt_date_from';
      const RECEIPT_DATE_TO = 'receipt_date_to';
      const ID_STATUS_ARRAY = 'id_status_array';

      delete variables.current.filter;
      delete variables.current.orderBy;
      delete variables.current.id_status_array;
      variables.current = {};
      variables.current.filter = {};

      let from;
      let to;

      Object.entries(initialFilters).forEach(([key, value]) => {
        if (key === DATE_RANGE) {
          [from, to] =
            initialFilters[DATE_RANGE]?.map((val: any) => {
              if (val?._z) {
                delete val._z; //Intencional: Esto se borra para evitar el error "Uncaught (in promise) Error: TypeError: mom._z.utcOffset is not a function" que sucedía con los valores iniciales del datePicker
              }
              return val ? formatDate(val) : undefined;
            }) || [];
        } else if (key === 'status') {
          variables.current.filter[ID_STATUS_ARRAY] = value;
        } else {
          if (
            Tools.isDefined(value) &&
            (!Array.isArray(value) || value.length > 0)
          ) {
            variables.current.filter[key] = value;
          }
        }
      });

      if (from) {
        variables.current.filter[RECEIPT_DATE_FROM] = from;
      }
      if (to) {
        variables.current.filter[RECEIPT_DATE_TO] = to;
      }

      if (receiptToHighlight) {
        setReceiptToHighlight(undefined);
      }

      const search: any = ABM.valuesResult(params);
      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }
      getAvailableQuota(selectedTenantId);

      if (!useLastFilter) {
        sessionStorage.setItem(
          EnumsValues.SessionStorageKeys.FromDateMonitorPage,
          variables.current.filter[RECEIPT_DATE_FROM] || undefined,
        );
        sessionStorage.setItem(
          EnumsValues.SessionStorageKeys.ToDateMonitorPage,
          variables.current.filter[RECEIPT_DATE_TO] || undefined,
        );
        firstLoad.current = false;
      }

      setUseLastFilter(false);

      if (selectedGrouper) {
        sessionStorage.setItem(
          EnumsValues.SessionStorageKeys.InvoiceGrouperId,
          String(selectedGrouper),
        );
        if (groupIdSelected === undefined) setGroupIdSelected(selectedGrouper);
        variables.current.filter['grouper_id'] = selectedGrouper;
      }

      if (selectedFilters.length > 0) {
        variables.current.filter['id_status_array'] = selectedFilters;
        sessionStorage.setItem(
          EnumsValues.SessionStorageKeys.InvoiceselectedFilters,
          JSON.stringify(selectedFilters),
        );
      }

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      if (selectedTenantId)
        variables.current.filter.tenants = [selectedTenantId];
      const countPromise = customRequest({
        query: Query.receiptHeaderCount,
        variables: variables.current,
      }).then((response: { count: number }) => response.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;
      const dataPromise = customRequest({
        query: Query.receiptsHeaders,
        variables: variables.current,
      });

      let [total, data] = await Promise.all([countPromise, dataPromise]);
      refreshNotification();

      if (updatedReceiptId && updatedReceiptStatusId) {
        const dataRH: IReceiptHeader = await customRequest({
          query: Query.receiptHeader,
          variables: {
            id: Number(updatedReceiptId),
          },
        });

        if (dataRH && updatedReceiptStatusId !== dataRH.status_id) {
          openNotification({
            msj: t('ReviewReceipt.message.receiptReviewStatusChangeAlt'),
            descr: dataRH.filename,
            type: 'info',
          });
        }
      }

      if (updatedReceiptId && data.length) {
        data = await orderElementFirst(data, updatedReceiptId);
        setReceiptToHighlight(updatedReceiptId);
        setUpdatedReceiptId(undefined);
      }
      return {
        current: params.current,
        data: data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const getStates = async (initial?: boolean) => {
    if (groupIdSelected !== selectedGrouper && !initial) {
      setGroupIdSelected(selectedGrouper);
      setSelectedFilters([]);
    }
    try {
      const states: IStatus[] = await customRequest({
        query: Query.states,
        variables: {
          filter: {
            grouper_id: selectedGrouper ? selectedGrouper : null,
          },
          orderBy: {
            field: 'id',
            direction: 'asc',
          },
        },
      });

      if (selectedGrouper !== selectedGrouperRef.current) {
        return;
      }

      if (states) {
        const options1: SelectProps['options'] = [];
        for (const state of states) {
          options1.push({
            label: state.name,
            value: state.id,
          });
        }
        setOptions(options1);
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const getTenant = async (tenant_id: number) => {
    setLoadingTenant(true);
    try {
      const data: ITenant = await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            id: tenant_id,
          },
        },
      });
      setSelectedIntegration(data.integration);
    } catch {
      // Intentional
    }
    setLoadingTenant(false);
  };

  const getGroupers = async () => {
    try {
      const data: IGrouper[] = await customRequest({
        query: Query.groupers,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
          orderBy: {
            field: 'id',
            direction: 'asc',
          },
        },
      });
      setGroupers(data);
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const getPageLimitNumber = async () => {
    try {
      if (selectedTenantId) {
        const data: ITenantSettingCurrentValue = await customRequest({
          query: Query.tenantSettingCurrentValue,
          variables: {
            tenant_id: selectedTenantId,
            name: EnumsValues.TenantSettingNames.MaxPagePerDocument,
          },
        });

        maxPagePerDocument.current =
          data.value || DEFAULT_MAX_PAGE_PER_DOCUMENT;
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const {
    detailsPageInput,
    discountRatesPageInput,
    headersPageInput,
    ivaRatesPageInput,
    othersRatesPageInput,
  } = useMemo(() => {
    return {
      headersPageInput: {
        id: t('excel.headers.id'),
        receipt_number: t('excel.headers.receipt_number'),
        receipt_type: t('excel.headers.receipt_type'),
        receipt_letter: t('excel.headers.receipt_letter'),
        receipt_date: t('excel.headers.receipt_date'),
        due_date: t('excel.headers.due_date'),
        sender_tax_type: t('excel.headers.sender_tax_type'),
        sender_tax_number: t('excel.headers.sender_tax_number'),
        sender_name: t('excel.headers.sender_name'),
        receipt_currency: t('excel.headers.receipt_currency'),
        receipt_subtotal: t('excel.headers.receipt_subtotal'),
        receipt_tax_total_calculated: t(
          'excel.headers.receipt_tax_total_calculated',
        ),
        receipt_tax_total_no_assigned_calculated: t(
          'excel.headers.receipt_tax_total_no_assigned_calculated',
        ),
        receipt_discount_total: t('excel.headers.receipt_discount_total'),
        receipt_total: t('excel.headers.receipt_total'),
        receipt_verification_code: t('excel.headers.receipt_verification_code'),
        receipt_verification_due_date: t(
          'excel.headers.receipt_verification_due_date',
        ),
        purchase_order: t('excel.headers.purchase_order'),
        related_document_name: t('excel.headers.related_document_name'),
        related_document_number: t('excel.headers.related_document_number'),
        exchange_rate_quote_value: t('excel.headers.exchange_rate_quote_value'),
        exchange_rate_quote_currency: t('excel.headers.exchange_rate_quote_currency'),
        exchange_rate_quote_description: t('excel.headers.exchange_rate_quote_description'),
      },
      detailsPageInput: {
        id: t('excel.details.id'),
        header_id: t('excel.details.header_id'),
        receipt_line_description: t('excel.details.receipt_line_description'),
        receipt_line_quantity: t('excel.details.receipt_line_quantity'),
        receipt_line_unit_price: t('excel.details.receipt_line_unit_price'),
        receipt_line_discount_percentage: t(
          'excel.details.receipt_line_discount_percentage',
        ),
        receipt_line_discount_price: t(
          'excel.details.receipt_line_discount_price',
        ),
        receipt_line_tax_rate: t('excel.details.receipt_line_tax_rate'),
        receipt_line_tax_total: t('excel.details.receipt_line_tax_total'),
        receipt_line_subtotal: t('excel.details.receipt_line_subtotal'),
      },
      ivaRatesPageInput: {
        header_id: t('excel.iva_rates.header_id'),
        receipt_tax_rate: t('excel.iva_rates.receipt_tax_rate'),
        amount: t('excel.iva_rates.amount'),
      },
      othersRatesPageInput: {
        header_id: t('excel.others_rates.header_id'),
        receipt_tax_name: t('excel.others_rates.receipt_tax_name'),
        amount: t('excel.others_rates.amount'),
      },
      discountRatesPageInput: {
        header_id: t('excel.discount_rates.header_id'),
        amount: t('excel.discount_rates.amount'),
        description: t('excel.discount_rates.description'),
      },
    };
  }, [t]);

  const exportReceiptHeaders = async (format: string) => {
    try {
      const currentFilters = { ...variables.current.filter };
      if (selectedRowKeys.length) {
        currentFilters.ids = selectedRowKeys;
      }

      const data: IFileData = await customRequest({
        query: Query.exportReceiptHeaders,
        variables: {
          format: format,
          filter: {
            ...currentFilters,
            receipt_type_id: [
              EnumsValues.ReceiptType.Factura,
              EnumsValues.ReceiptType.NotaCredito,
              EnumsValues.ReceiptType.NotaDebito,
              EnumsValues.ReceiptType.TiqueControladorFiscal,
            ],
          },
          headersPageInput,
          detailsPageInput,
          ivaRatesPageInput,
          othersRatesPageInput,
          discountRatesPageInput,
          orderBy: {
            field: 'id',
            direction: 'asc',
          },
        },
      });
      if (data) {
        Tools.downloadFile(data);
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const exportReceiptHeadersDynamic = async ({
    format,
    exportMapKey,
  }: {
    format: string;
    exportMapKey?: string;
  }) => {
    try {
      const currentFilters = { ...variables.current.filter };
      if (selectedRowKeys.length) {
        currentFilters.ids = selectedRowKeys;
      }

      const data: IFileData = await customRequest({
        query: Query.exportReceiptHeadersDynamic,
        variables: {
          format: format,
          filter: {
            ...currentFilters,
            receipt_type_id: [
              EnumsValues.ReceiptType.Factura,
              EnumsValues.ReceiptType.NotaCredito,
              EnumsValues.ReceiptType.NotaDebito,
              EnumsValues.ReceiptType.TiqueControladorFiscal,
            ],
          },
          columnsPerPages: {
            columnsPerPage: [],
          },
          orderBy: {
            field: 'id',
            direction: 'asc',
          },
          exportMapKey,
        },
      });
      if (data) {
        Tools.downloadFile(data);
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const exportReceiptHeadersByEmail = async (values: { email: string }) => {
    try {
      const { email } = values;
      if (email) {
        const emailsArray = email.split(',');
        const currentFilters = { ...variables.current.filter };
        if (selectedRowKeys.length) {
          currentFilters.ids = selectedRowKeys;
        }
        await customRequest({
          query: Query.exportReceiptHeadersByEmails,
          variables: {
            format: 'xlsx',
            filter: currentFilters,
            headersPageInput,
            detailsPageInput,
            ivaRatesPageInput,
            othersRatesPageInput,
            discountRatesPageInput,
            orderBy: {
              field: 'id',
              direction: 'asc',
            },
            emails: { emails: emailsArray },
          },
        });
        openNotification({
          msj: t('message.exportReceiptByEmail.success'),
          type: 'success',
        });
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const getInvitationData = async (invitation_uid: string) => {
    try {
      const data = await customRequest({
        query: Query.checkInvitation,
        variables: {
          invitation_uid,
        },
      });
      setInvitationData(() => data);
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const handleResponseInvitation = useCallback(
    async (accept) => {
      try {
        if (invitationData)
          await customRequest({
            query: Mutation.invitationResponse,
            variables: {
              invitation_uid: invitationData.uid,
              accept,
            },
          });

        openNotification({
          type: 'success',
          msj: t('message.updateSuccess'),
          context: 'Receipt.handleResponseInvitation.2',
        });
        getTenantsAssociatedWithUser();
        history.replace({
          search: '',
        });
      } catch (error) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    },
    [invitationData],
  );

  const handleGrouperChange = (grouperId?: number) => {
    if (grouperId === undefined) {
      sessionStorage.removeItem(
        EnumsValues.SessionStorageKeys.InvoiceGrouperId,
      );
    }
    setSelectedGrouper(grouperId);
  };

  const getReceiptHeaderPdf = async (id: number) => {
    try {
      const data: IBaseFile = await customRequest({
        query: Query.getReceiptHeaderPdf,
        variables: {
          id,
        },
      });
      Tools.downloadFile(data);
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const orderElementFirst = async (array: any[], id: number) => {
    const reorderedArray = [...array];
    const movedElement = array.find((item) => item.id === id);
    if (movedElement) {
      const index = array.findIndex((item) => item.id === id);
      reorderedArray.splice(index, 1);
      reorderedArray.unshift(movedElement);
      return reorderedArray;
    }
    return array;
  };

  const removeReceipt = async (id: number) => {
    if (!id || !selectedTenantId) return;

    try {
      await customRequest({
        mutation: Mutation.removeReceipt,
        variables: {
          receipt_id: id,
          tenant_id: selectedTenantId,
        },
      });
      openNotification({
        msj: t('message.deleteSuccess'),
        type: 'success',
      });

      actionRef.current?.reloadAndRest && actionRef.current.reloadAndRest();
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const setReceiptStatusDiscarded = async (recordError: IReceiptHeader) => {
    if (!recordError || !selectedTenantId) return;

    try {
      await customRequest({
        mutation: Mutation.setReceiptHeaderStatusDiscarded,
        variables: {
          input: {
            receipt_header_id: recordError.id,
          },
        },
      });
      openNotification({
        msj: t('log_action.updateReceiptStatusToDiscarded'),
        filename: recordError?.filename,
        type: 'success',
      });
      actionRef.current?.reloadAndRest && actionRef.current.reloadAndRest();
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const validStatusForDelete = [
    EnumsValues.ReceiptStatus.Processed,
    EnumsValues.ReceiptStatus.Incomplete,
    EnumsValues.ReceiptStatus.Integrated,
  ];

  const validStatusToSetDiscarded = [
    EnumsValues.ReceiptStatus.Processed,
    EnumsValues.ReceiptStatus.ToReview,
  ];
  const removeRecord = (value: any) => {
    if (!validStatusForDelete.includes(value.status_id)) return;

    Modal.confirm({
      content: (
        <>
          <div>
            {t('message.confirmRemoveReceipt', {
              receiptNumber: value.receipt_number,
            })}
          </div>
        </>
      ),
      onOk: () => {
        removeReceipt(value.id);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const discardRecord = (recordError: IReceiptHeader) => {
    if (!recordError) return;
    Modal.confirm({
      content: (
        <>
          <div>
            <p className="modal-discard-title">{t('action.discardReceipt')}</p>
            {t('message.confirmSetReceiptStatusDiscarded', {
              receiptNumber: recordError.receipt_number,
            })}
          </div>
        </>
      ),
      icon: (
        <span
          className="material-symbols-outlined receipt-exclamation-icon"
          translate="no"
        >
          error
        </span>
      ),
      width: '448px',
      onOk: () => {
        setReceiptStatusDiscarded(recordError);
      },
      okText: t('action.discarded'),
      okButtonProps: {
        className: 'save-btn',
      },
    });
  };

  const columns = useCallback(
    (_editMode: boolean): ExportableColumn<IReceiptHeader>[] => [
      {
        export: true,
        dataIndex: 'id',
        title: t('entity.id'),
        sorter: true,
        render: (_, record) => record.id,
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
        width: 70,
      },
      {
        export: true,
        dataIndex: 'sender',
        title: t('entity.sender'),
        sorter: true,
        render: (_, record) =>
          record.sender || record.receipt_file ? (
            <Tooltip
              title={
                record.sender
                  ? record.sender.name
                  : record.receipt_file?.filename
              }
              trigger={tooltipTrigger}
            >
              <span translate="no">
                {record.sender
                  ? record.sender.name
                  : record.receipt_file?.filename}
              </span>
            </Tooltip>
          ) : (
            '-'
          ),
        align: 'left',
        hideInForm: true,
        hideInSearch: true,
        className: 'sender-column',
      },
      {
        export: true,
        dataIndex: 'receipt_type',
        title: t('entity.receipt_type'),
        sorter: true,
        renderDataExport: (record: { receipt_type: IReceiptType }) =>
          t(record.receipt_type?.translation_key as never, {
            defaultValue: record.receipt_type?.name || '-',
          }),

        render: (_, record) =>
          t(record.receipt_type?.translation_key as never, {
            defaultValue: record.receipt_type?.name,
          }) ? (
            <Tooltip
              title={t(record.receipt_type.translation_key as never, {
                defaultValue: record.receipt_type.name,
              })}
              trigger={tooltipTrigger}
            >
              <div
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {t(record.receipt_type?.translation_key as never, {
                  defaultValue: record.receipt_type?.name,
                })}
              </div>
            </Tooltip>
          ) : (
            '-'
          ),
        align: 'left',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: 'receipt_letter',
        title: t('entity.receiptLetter'),
        sorter: true,
        renderDataExport: (record: { receipt_letter: IReceiptLetter }) =>
          t(record.receipt_letter?.translation_key as never, {
            defaultValue: record.receipt_letter?.name || '-',
          }),
        render: (_, record) =>
          t(record.receipt_letter?.translation_key as never, {
            defaultValue: (
              <span translate="no"> {record.receipt_letter?.name || '-'}</span>
            ),
          }),
        align: 'left',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: 'receipt_number',
        title: t('entity.number'),
        sorter: true,
        render: (_, record) => record.receipt_number || '-',
        align: 'left',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: 'receipt_date',
        title: t('entity.shortReceiptDate'),
        sorter: true,
        render: (_, record) =>
          getDate({ element: moment(record.receipt_date).toDate() }) || '-',
        align: 'left',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'receipt_date_range',
        title: t('entity.uploadDate'),
        formItemProps: { className: 'large-label', labelAlign: 'left' },
        sorter: true,
        renderFormItem: () => (
          <DatePicker.RangePicker
            allowEmpty={[true, true]}
            placeholder={[t('entity.dateFrom'), t('entity.dateTo')]}
            format={getUserDateFormat()}
          ></DatePicker.RangePicker>
        ),
        align: 'left',
        hideInTable: true,
        hideInForm: true,
      },
      {
        export: false,
        title: t('entity.status'),
        dataIndex: 'status',
        formItemProps: { className: 'large-label' },
        sorter: true,
        renderFormItem: () => (
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={t('message.placeholder.selectStatus')}
            getPopupContainer={(node) => node.parentNode}
            options={options}
            value={selectedFilters}
          />
        ),
        align: 'left',
        hideInTable: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'status',
        title: t('entity.status'),
        sorter: true,
        renderDataExport: (record: { status: IStatus }) =>
          t(record.status?.translation_key as never, {
            defaultValue: record.status?.name || '-',
          }),
        render: (_, record) => (
          <Tag color={record.status.color}>
            {t(record.status.translation_key as never, {
              defaultValue: record.status.name || '-',
            })}
          </Tag>
        ),
        align: 'center',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: 'due_date',
        title: t('entity.shortDueDate'),
        sorter: true,
        render: (_, record) =>
          record.due_date
            ? getDate({ element: moment(record.due_date).toDate() }) || '-'
            : '-',
        align: 'left',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: 'receipt_currency_id',
        title: t('entity.currencyType'),
        sorter: false,
        renderDataExport: (record: { currency_type
          : ICurrencyType }) =>
           
            record.currency_type
            ?.code || '-',
        render: (_, record) =>
              <span translate="no"> {record.currency_type?.code || '-'}</span>
            ,
        align: 'left',
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: 'total',
        title: t('entity.amount'),
        render: (_, record) =>
          record.receipt_total === undefined || record.receipt_total === null
            ? '-'
            : Tools.formatCurrencyWithoutSymbol(record.receipt_total, { minDigits: 2 }),
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
      },

      {
        title: t('entity.actions'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        align: 'center',
        render: (_, record) => {
          const items: ExtendedMenuItemType[] = [
            {
              key: '0',
              className: 'sideMenu-icon',
              icon: (
                <span
                  className="material-symbols-outlined action-menu-icon"
                  translate="no"
                >
                  search
                </span>
              ),
              label: t('action.view'),
              onClick: () => {
                setViewModalReceipt({
                  open: true,
                  data: record,
                });
              },
              disabled: false,
              hidden: false,
            },
            {
              key: '1',
              icon: (
                <span
                  className="material-symbols-outlined action-menu-icon"
                  translate="no"
                >
                  cloud_download
                </span>
              ),
              label: t('action.download'),
              onClick: () => {
                record.receipt_file_id && getReceiptHeaderPdf(record.id);
              },
              disabled:
                record.status_id === EnumsValues.ReceiptStatus.Loaded ||
                !record.receipt_file_id,
              hidden: false,
            },
            {
              key: '2',
              icon: (
                <span
                  translate="no"
                  className="material-symbols-outlined action-menu-icon"
                  style={
                    record.status_id === EnumsValues.ReceiptStatus.Loaded
                      ? { opacity: 0.5 }
                      : {}
                  }
                >
                  checklist
                </span>
              ),
              label: t('action.viewLog'),
              onClick: () =>
                history.push({
                  pathname: '/app/viewLogs',
                  state: { receiptHeader: record },
                }),
              disabled: record.status_id === EnumsValues.ReceiptStatus.Loaded,
              hidden: !Authorization.security(
                functions,
                EnumsValues.Functions.LogRead,
              ),
            },
            {
              key: '3',
              icon: (
                <span
                  className="material-symbols-outlined action-menu-icon"
                  translate="no"
                >
                  quick_reference_all
                </span>
              ),
              label: t('ReviewReceipt.reviewReceipt'),
              onClick: () => {
                history.push(`reviewReceipt/${record.id}`);
              },
              disabled: record.status_id !== EnumsValues.ReceiptStatus.ToReview,
              hidden: false,
              tooltipMessageOnDisabled: t('action.cannotReviewReceipt'),
            },
            {
              key: '4',
              icon: (
                <span
                  className="material-symbols-outlined action-menu-icon"
                  translate="no"
                >
                  block
                </span>
              ),
              label: t('action.discarded'),
              onClick: () => {
                discardRecord(record);
              },
              disabled:
                !Authorization.security(
                  functions,
                  EnumsValues.Functions.DeletePendingReceipt,
                ) || !validStatusToSetDiscarded.includes(record.status_id),
              hidden: false,
              tooltipMessageOnDisabled: t(
                'action.cannotSetReceiptStatusDiscarded',
              ),
            },
            {
              key: '5',
              icon: (
                <span
                  className="material-symbols-outlined action-menu-icon"
                  translate="no"
                >
                  delete
                </span>
              ),
              label: t('action.remove'),
              onClick: () => {
                removeRecord(record);
              },
              disabled:
                !Authorization.security(
                  functions,
                  EnumsValues.Functions.DeletePendingReceipt,
                ) || !validStatusForDelete.includes(record.status_id),
              hidden: !Authorization.security(
                functions,
                EnumsValues.Functions.DeletePendingReceipt,
              ),
              tooltipMessageOnDisabled: t('action.cannotRemoveReceipt'),
            },
          ];

          return (
            <div onClick={(e) => e.stopPropagation()}>
              <ActionMenu items={items} />
            </div>
          );
        },
      },
    ],
    [editForm, options, selectedFilters],
  );

  const grouperTab = (grouper: IGrouper) => (
    <div
      className={`state-tab ${
        selectedGrouper === grouper.id ? 'selected' : ''
      }`}
      onClick={() => handleGrouperChange(grouper.id)}
      key={grouper.tab_title}
    >
      {t(grouper.translation_key_tab_title as never, {
        defaultValue: grouper.tab_title || '-',
      })}
    </div>
  );

  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });

  const [columnsStateMap, setColumnsStateMap] = useState<
    Record<string, ColumnsState>
  >({
    id: {
      show: false,
    },
    due_date: {
      show: false,
    },
  });

  const getLimitMaxSizeFileSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.LimitMaxSizeFile },
        },
      });
      setMaxSizeFile(Number(data.setting_value));
    } catch (error) {
      //intentional
    }
  };
  const createPendingReceipts = async (
    tenant_id: number,
    file: RcFile,
    validateDuplicated: boolean = true,
  ) => {
    try {
      return await customFileRequest(
        {
          maxRetriesNumber:
            EnumsValues.CustomValues.MaxRetriesUploadingReceipts,
          mutation: Mutation.createPendingReceipts,
          variables: {
            tenant_id,
            file: {
              filename: file?.name,
              mimetype: file?.type,
              encoding: 'base64',
            },
            validate_duplicity: validateDuplicated,
          },
        },
        [
          {
            file: file,
            path: 'variables.file',
          },
        ],
      );
    } catch (error: any) {
      return error;
    }
  };

  function mostrarModalDeAdvertencia(filename: string) {
    return new Promise((resolve) => {
      Modal.confirm({
        className: 'modal-duplicated-invoice',
        content: (
          <>
            <div className="ant-modal-confirm-title">
              <p>{t('ModalReceiptDuplicated.duplicatedReceipt')}</p>
            </div>
            <div className="modal-font">
              <p className="modal-description-loadead">
                {t('ModalReceiptDuplicated.duplicatedReceiptDescription')}
              </p>
              <p className="modal-description-filename">{filename}</p>
              <p className="modal-description-ask-reload">
                {t('ModalReceiptDuplicated.duplicatedReceiptAskLoad')}
              </p>
            </div>
          </>
        ),
        okText: t('ModalReceiptDuplicated.reloadFile'),
        cancelText: t('ModalReceiptDuplicated.discardFile'),
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
          refreshTableClick(false);
        },
        okButtonProps: { className: 'save-btn' },
      });
    });
  }

  const markFileAsFinishedProcessing = async () => {
    contador.current--;

    if (contador.current === EnumsValues.ConstNumbers.zero) {
      if (
        fileListDuplicatedRef.current.length > EnumsValues.ConstNumbers.zero &&
        selectedTenantId
      ) {
        for (const iterator of fileListDuplicatedRef.current) {
          await mostrarModalDeAdvertencia(iterator.name)
            .then(async (response) => {
              if (response) {
                const responseDuplicated = await createPendingReceipts(
                  selectedTenantId,
                  iterator,
                  false,
                );
                if (responseDuplicated?.filename) {
                  await openNotification({
                    msj: t('UploadDragAndDrop.uploadSuccessful'),
                    filename: responseDuplicated.filename,
                    type: 'success',
                  });
                  refreshNotification();
                } else {
                  openNotification({
                    msj: responseDuplicated?.message
                      ? responseDuplicated.message
                      : t('error.abm.generalError'),
                    type: 'error',
                  });
                  counterProcessedMinusDuplicated.current--;
                }
              } else {
                counterProcessedMinusDuplicated.current--;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
      fileListDuplicatedRef.current = [];
      setLoadDisabled(() => false);
      setLoading(false);
      await getAvailableQuota(selectedTenantId);

      counterProcessedMinusDuplicated.current =
        counterProcessedMinusDuplicated.current -
        fileListMaxSizeRef.current.length -
        counterFileNotProcessed.current;
      fileListMaxSizeRef.current = [];
      counterFileNotProcessed.current = 0;
      const fileNotSupported =
        counterFileNotSupported.current ===
        counterProcessedMinusDuplicated.current;
      counterFileNotSupported.current = EnumsValues.ConstNumbers.zero;

      if (
        counterProcessedMinusDuplicated.current >
          EnumsValues.ConstNumbers.zero &&
        fileLimitExceeded.current === false &&
        fileQuotaExceeded.current === false &&
        fileNotSupported === false
      ) {
        refreshTableClick(true);
      } else {
        refreshTableClick(false);
      }
      if (fileLimitExceeded.current === true) {
        openNotification({
          msj: t('error.abm.fileLimitExceeded', {
            maxFiles: EnumsValues.SystemLimits.MaxSimultaneousFileUpload,
          }),
          type: 'error',
        });
      } else if (fileQuotaExceeded.current === true) {
        openNotification({
          msj: t('error.abm.fileQuotaExceeded'),
          type: 'error',
        });
      }
    }
  };

  const handleChange = ({
    file,
    fileList,
  }: {
    file: UploadFile;
    fileList: UploadFile<any>[];
  }) => {
    setFileListState(fileList);
    fileListRef.current = fileList;
    fileQuotaExceeded.current = false;
    fileLimitExceeded.current = false;
    if (contador.current !== 0) {
      setLoadDisabled(true);
      setLoading(true);
    }

    if (
      availableQuota &&
      fileList.length >
        Math.min(
          EnumsValues.SystemLimits.MaxSimultaneousFileUpload,
          availableQuota,
        )
    ) {
      markFileAsFinishedProcessing();
      if (
        fileList.length > EnumsValues.SystemLimits.MaxSimultaneousFileUpload
      ) {
        fileLimitExceeded.current = true;
      } else {
        fileQuotaExceeded.current = true;
      }
    } else {
      const fileError = file.status === 'error'; // No need to show success message if all files are with errors

      const fileRemoved = file.status === 'removed'; // No need to show success message if file is removed

      const fileRc = file as RcFile;
      if (fileRc.size > maxSizeFile && !fileRemoved && !fileError) {
        markFileAsFinishedProcessing();
        openNotification({
          msj: t('error.abm.uploadFileFailed'),
          descr: t('error.abm.fileMaxSizeExceeded'),
          filename: file.name,
          type: 'warning',
        });
        fileListMaxSizeRef.current.push(fileRc);
      }
    }
  };

  let disabledDragAndDrop =
    availableQuota === 0 ||
    notifications?.quotaIsExpired ||
    loadDisabled === true ||
    tenantsAssociatedToUser.find((tenant) => tenant.id === selectedTenantId)
      ?.tenant_status_id === TenantStatus.Inactiva;

  const props: UploadProps<any> = useMemo(
    () => ({
      name: 'file',
      multiple: true,
      listType: 'picture',
      className: 'upload-list-inline',
      fileList: fileListState,

      //MaxCount no es necesario ya que se controla en handleChange
      beforeUpload: (_file, fileList) => {
        setFileListState([]);
        fileListRef.current = [];
        contador.current = fileList.length;
        counterProcessedMinusDuplicated.current = fileList.length;
        if (
          fileList.length >
          Math.min(
            EnumsValues.SystemLimits.MaxSimultaneousFileUpload,
            availableQuota || 0,
          )
        ) {
          return Promise.reject(false);
        }
      },
      accept: '.pdf, .jpg, .jpeg, .png, .tiff, .bmp',

      onChange: ({ file, fileList }) => handleChange({ file, fileList }),
      itemRender: (originNode, file) =>
        file.status === 'error' ? originNode : null,
      customRequest: async (options) => {
        const { file, onSuccess, onError } = options;
        const fileRc = file as RcFile;

        if (
          selectedTenantId &&
          fileRc.size <= maxSizeFile &&
          fileListRef.current.some((item: any) => item.uid === fileRc.uid)
        ) {
          const response = await createPendingReceipts(
            selectedTenantId,
            fileRc,
          );

          if (response?.filename) {
            onSuccess && setTimeout(onSuccess, 0);
            await openNotification({
              msj: t('UploadDragAndDrop.uploadSuccessful'),
              filename: response.filename,
              type: 'success',
            });
            refreshNotification();
          }
          if (
            response?.status_code ===
              EnumsValues.ErrorsStatusCode.duplicatedFileCode &&
            onError
          ) {
            fileListDuplicatedRef.current.push(fileRc);
          } else if (response?.message && onError) {
            if (
              response.status_code ===
              EnumsValues.ErrorsStatusCode.fileNotSupportedCode
            ) {
              counterFileNotSupported.current++;
            } else {
              counterFileNotProcessed.current++;
            }
            openNotification({
              msj: response.message,
              type: 'error',
            });
          }
          markFileAsFinishedProcessing();
          if (
            fileListRef.current[fileListRef.current.length - 1]?.uid ===
            fileRc.uid
          ) {
            setFileListState([]);
            fileListRef.current = [];
          }
        }
      },
      disabled: disabledDragAndDrop,
    }),
    [availableQuota, fileListState, disabledDragAndDrop],
  );

  function handleSelectedRows(selectedRows: any) {
    setSelectedRowKeys(selectedRows);
    // Call your method here with the selectedIds array
  }

  const getRowClassName = (record: IReceiptHeader) => {
    if (receiptToHighlight && record.id === receiptToHighlight)
      return 'highlighted-row';
    const isSelected = selectedRowKeys.includes(record.id);
    return isSelected ? 'selected-row' : '';
  };

  const formatDate = (date: any) => {
    return moment(date).format(FORMAT_DATE_TIME_3);
  };

  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'receipt-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'receipt-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };

  useEffect(() => {
    if (selectedTenantId) {
      getTenant(selectedTenantId);
    }
  }, [selectedTenantId]);

  return (
    <>
      <div className="error-container">{errorCard}</div>
      {notifications?.missDataInDecisionTable && (
        <ReprocessCard
          icon={
            <FileAlertOutlined style={{ color: '#FFB156', fontSize: 38 }} />
          }
          title={t('ReprocessCard.BraulioNeedsYourHelp')}
          description1={t('ReprocessCard.missDataInDecisionTable')}
          description2={
            <span>
              {t('ReprocessCard.reviewThe')}{' '}
              <Link
                to="/app/administration/data_relation?complete=false"
                style={{ textDecoration: 'underline' }}
              >
                {t('page.dataRelation')}
              </Link>{' '}
              {t('ReprocessCard.thenReprocess')}
            </span>
          }
          hideButton={
            !Authorization.security(
              functions,
              EnumsValues.Functions.reprocessIncompleteReceiptHeaders,
            )
          }
          tableActionRef={actionRef}
        />
      )}
      <div className="upload-container">
        <UploadDragAndDrop
          uploadProps={props}
          maxPagePerDocument={maxPagePerDocument.current}
        />
        <AvailableQuotaCard version="2" />
      </div>
      <div id="receipts-uploaded" />
      <div className="state-tab-container">
        <div
          className={`state-tab ${selectedGrouper ? '' : 'selected'}`}
          onClick={() => handleGrouperChange()}
        >
          {t('entity.all')}
        </div>
        {groupers.map(grouperTab)}
      </div>
      <ProTable<IReceiptHeader>
        className="protable-receipt"
        onSizeChange={mobileOnSizeChangeProTable}
        size="small"
        rowClassName={(record, _) => getRowClassName(record)}
        components={{
          table: showComponent(),
        }}
        onRow={(record) => {
          return {
            style: {
              cursor: 'pointer',
            },
            onClick: () => {
              if (record.status_id === EnumsValues.ReceiptStatus.ToReview) {
                history.push(`reviewReceipt/${record.id}`);
              } else {
                setViewModalReceipt({
                  open: true,
                  data: record,
                });
              }
            },
          };
        }}
        actionRef={actionRef}
        rowKey="id"
        formRef={formRef}
        options={{
          reload: false,
          density: false,
        }}
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
        search={false}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IReceiptHeader>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        params={{
          sorter,
        }}
        toolBarRender={(_, { selectedRowKeys }) => [
          selectedRowKeys && selectedRowKeys.length !== 0 && (
            <div key="receipt-selector">
              {selectedRowKeys.length > 1 ? (
                <span className="receipt-selector-info">
                  {selectedRowKeys.length} {t('label.selectedPlural')}
                </span>
              ) : (
                <span className="receipt-selector-info">
                  {selectedRowKeys.length} {t('label.selected')}
                </span>
              )}
            </div>
          ),
          <ToolbarFilterOrder
            onSearch={handleSearch}
            showButtonNew={false}
            columns={columns(false)}
            onFinishFilter={(values) => {
              setSelectedFilters(values.status || []);
              setInitialFilters(values);
              if (actionRef.current?.reload) {
                actionRef.current.reload(true);
              }
            }}
            filterNames={INPUT_SEARCH_PLACEHOLDER}
            initialValuesFilter={initialFilters}
          />,
          <>
            {/* TODO: Boton de seleccionar todo, evaluar si es necesario reubicarlo, y agregar traducción */}

            <Button
              className="ant-btn ant-btn-primary"
              onClick={() => setViewSendEmailModal(true)}
            >
              {t('action.sendMail')}
            </Button>
            {loadingTenant ? (
              <>
                <Spin size="small" />
              </>
            ) : (
              <>
                {selectedIntegration &&
                selectedIntegration.code ===
                  EnumsValues.IntegrationCode.odoo ? (
                  <>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            onClick={() => exportReceiptHeaders('xlsx')}
                          >
                            {Tools.capitalize(t('entity.excel'))}
                          </Menu.Item>
                          <Menu.Item
                            onClick={() =>
                              exportReceiptHeadersDynamic({
                                format: 'xlsx',
                                exportMapKey:
                                  EnumsValues.ExportReceiptHeadersMapKeys.Odoo,
                              })
                            }
                          >
                            {Tools.capitalize(t('entity.odoo'))}
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <Button className="ant-btn ant-btn-primary">
                        {t('action.download')}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </>
                ) : (
                  <>
                    <Button
                      className="ant-btn ant-btn-primary"
                      onClick={() => exportReceiptHeaders('xlsx')}
                    >
                      {t('action.download')}
                    </Button>
                  </>
                )}
              </>
            )}
          </>,
          <>
            <div>
              <div
                ref={animatedButton}
                className={`${pulseAnimation ? 'bg-pulse' : ''}`}
              ></div>
              <Button
                className="btn-refresh"
                disabled={disabledRefresh.current}
                onClick={() => refreshTableClick(false)}
              >
                <span
                  className="material-symbols-outlined refresh-icon"
                  translate="no"
                >
                  cached
                </span>
              </Button>
            </div>
          </>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns(false)}
        rowSelection={{
          type: 'checkbox',
          onChange: handleSelectedRows,
          selectedRowKeys: selectedRowKeys,
          preserveSelectedRowKeys: true,
        }}
        tableAlertRender={false}
      />
      {viewSendEmailModal ? (
        <ExportSendEmail
          open={viewSendEmailModal}
          onCancel={() => {
            setViewSendEmailModal(false);
          }}
          onFinish={(values) => {
            exportReceiptHeadersByEmail(values);
            setViewSendEmailModal(false);
          }}
        ></ExportSendEmail>
      ) : null}
      {viewModalReceipt.open && (
        <ModalViewReceipt
          state={viewModalReceipt}
          onCancel={() => {
            setViewModalReceipt({
              open: false,
              data: undefined,
            });
          }}
        />
      )}
      {loading ? <PageLoading className="page-loading" /> : null}
    </>
  );
}
