import { DatePicker, FormInstance, Select, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { ExportableColumn } from '../../ABM/LocalExporter';
import FilterTag from '../FilterTag/FilterTag';
import './FilterTags.less';
import { Tools } from '../../../../shared';
import RangePickerTag from '../RangePickerTag/RangePickerTag';

interface FilterTagsProps {
  columns: ExportableColumn<any>[];
  formInstance: FormInstance<any>;
}

const FilterTags = (props: FilterTagsProps) => {
  const { columns, formInstance } = props;
  const [filterLabels, setFilterLabels] = useState<{
    [key: string]: { [key: string]: string };
  }>({});

  const formValues = Form.useWatch([], formInstance);

  useEffect(() => {
    const labels: { [key: string]: { [key: string]: string } } = {};
    columns.forEach((column) => {
      if (!column.hideInSearch && column.renderFormItem) {
        const formItem = column.renderFormItem(
          { type: 'form' },
          { defaultRender: () => null, type: 'form' },
          formInstance,
        );

        if (React.isValidElement(formItem) && formItem.type === Select) {
          const options = formItem.props.options || [];
          const columnLabels: { [key: string]: string } = {};
          options.forEach((option: { label: string; value: any }) => {
            columnLabels[option.value] = option.label;
          });
          labels[column.dataIndex as string] = columnLabels;
        }
      }
    });
    setFilterLabels(labels);
  }, [columns, formInstance]);

  const removeInvalidFilters = (filters: any) => {
    if (filters && typeof filters === 'object') {
      const validFilters: any = {};
      Object.keys(filters).forEach((key: string) => {
        if (
          Tools.isDefined(filters[key]) &&
          (!Array.isArray(filters[key]) || filters[key].length > 0)
        ) {
          validFilters[key] = filters[key];
        }
      });
      return validFilters;
    }
    return filters;
  };

  const getFilterLabel = (key: string, value: any): string => {
    if (filterLabels[key] && filterLabels[key][value]) {
      return filterLabels[key][value];
    }
    return value;
  };

  const handleCleanFilter = (key: string, value: any) => {
    const selectedField = formInstance.getFieldValue(key);

    if (Array.isArray(selectedField)) {
      formInstance.setFieldValue(
        key,
        selectedField.filter((v: any) => v !== value),
      );
    } else {
      formInstance.setFieldValue(key, undefined);
    }

    formInstance.submit();
  };

  if (!formValues) {
    return <div className="toolbar-filter-order__filter-tags" />;
  }

  return (
    <div className="toolbar-filter-order__filter-tags">
      {Object.keys(removeInvalidFilters(formValues)).map(
        (key: string, idx: number) => {
          const column = columns.find(
            (col) => col.dataIndex === key && !col.hideInSearch,
          );

          const element =
            column && column.renderFormItem
              ? column.renderFormItem(
                  { type: 'form' },
                  {
                    defaultRender: () => {
                      return <></>;
                    },
                    type: 'form',
                  },
                  formInstance,
                )
              : undefined;

          if (
            Array.isArray(formValues[key]) &&
            React.isValidElement(element) &&
            element.type !== DatePicker.RangePicker
          ) {
            return formValues[key].map((value: any) => (
              <FilterTag
                key={`${key}-${value}`}
                name={key}
                value={getFilterLabel(key, value)}
                onCleanFilter={() => handleCleanFilter(key, value)}
              />
            ));
          } else if (
            React.isValidElement(element) &&
            element.type === DatePicker.RangePicker
          ) {
            return (
              <div key={key + idx}>
                <RangePickerTag
                  formInstance={formInstance}
                  element={element}
                  keyValue={key}
                />
              </div>
            );
          } else {
            return (
              <FilterTag
                key={key + idx}
                name={key}
                value={getFilterLabel(key, formValues[key])}
                onCleanFilter={() => handleCleanFilter(key, formValues[key])}
              />
            );
          }
        },
      )}
    </div>
  );
};

export default FilterTags;
