enum InvoiceSetStatus {
  Open = 1,
  Closed = 2,
}

enum InvoiceStatus {
  NoPresentado = 1,
  Incompleto = 2,
  PorConformar = 3,
  Procesado = 4,
  Conformado = 5,
}

enum UserRoleSystem {
  Admin = 1,
  Validator = 2,
  Carrier = 3,
}

export enum SystemRolesId {
  Admin = 1,
  User = 2,
  TenantAdmin = 3,
  TenantUser = 4,
  APIUser = 5,
}

enum SystemRolesNames {
  Admin = 'Admin',
  User = 'User',
  TenantAdmin = 'Administrador de Organización',
  TenantUser = 'Usuario de Organización',
  APIUser = 'API User',
  APIPublic = 'API Pública',
}

enum ReturnFileType {
  ReturnInvoice = 1,
  ReturnInvoiceDetail = 2,
  CheckList = 3,
  OutputTop = 4,
}

enum ReturnOrderStatus {
  HD_NEW = 1,
  HD_In_Progress = 2,
  CD_NEW = 3,
  CD_In_Progress = 4,
  CD_DF_NEW = 5,
  CD_DF_In_Progress = 6,
  RL_NEW = 7,
  RL_In_Progress = 8,
  RL_Fiscal_Review = 9,
  QL_NEW = 10,
  QL_In_Progress = 11,
  FN_NEW = 12,
  FN_In_Progress = 13,
  Closed = 14,
}

export enum TenantStatus {
  Activa = 1,
  Inactiva = 2,
  BajaSolicitada = 3,
}

enum Functions {
  AppSettingRead = 'APP_SETTING_R',
  AppSettingUpdate = 'APP_SETTING_U',

  PermissionGet = 'PERMISSION_G',
  PermissionList = 'PERMISSION_L',
  PermissionUpdate = 'PERMISSION_U',

  RoleCreate = 'ROLE_C',
  RoleDelete = 'ROLE_D',
  RoleGet = 'ROLE_G',
  RoleList = 'ROLE_L',
  RoleUpdate = 'ROLE_U',
  RolePermissionCreate = 'ROLE_PERMISSION_C',
  RolePermissionGet = 'ROLE_PERMISSION_G',
  RolePermissionDelete = 'ROLE_PERMISSION_D',
  RolePermissionList = 'ROLE_PERMISSION_L',
  RolePermissionUpdate = 'ROLE_PERMISSION_U',

  TraceCreate = 'TRAC_C',
  TraceGet = 'TRAC_G',
  TraceList = 'TRAC_L',

  UserCreate = 'USER_C',
  UserDelete = 'USER_D',
  UserGet = 'USER_G',
  UserList = 'USER_L',
  UserRoleGet = 'USER_ROLE_G',
  UserRoleList = 'USER_ROLE_L',
  UserRoleUpdate = 'USER_ROLE_U',
  UserUpdate = 'USER_U',

  UserProfileUpdate = 'USER_PROFILE_U',
  ProfilePictureCreate = 'PROFILE_PICTURE_C',
  ProfilePictureRead = 'PROFILE_PICTURE_R',
  ProfilePictureUpdate = 'PROFILE_PICTURE_U',
  ProfilePictureDelete = 'PROFILE_PICTURE_D',

  ListReceiptHeader = 'RECEIPT_HEADER_L',

  AdministrationMenuRead = 'ADMINISTRATION_MENU_R',

  TenantRead = 'TENANT_R',
  TenantCreate = 'TENANT_C',
  TenantUpdate = 'TENANT_U',
  TenantDelete = 'TENANT_D',

  CreateTenantPicture = 'TENANT_PICTURE_C',
  ReadTenantPicture = 'TENANT_PICTURE_R',
  UpdateTenantPicture = 'TENANT_PICTURE_U',
  DeleteTenantPicture = 'TENANT_PICTURE_D',

  UpdateDecisionItem = 'DECISION_ITEM_U',
  CreateDecisionItem = 'DECISION_ITEM_C',
  ReadDecisionItem = 'DECISION_ITEM_R',
  DeleteDecisionItem = 'DECISION_ITEM_D',

  UpdateProduct = 'PRODUCT_U',
  CreateProduct = 'PRODUCT_C',
  ReadProduct = 'PRODUCT_R',
  DeleteProduct = 'PRODUCT_D',

  LogRead = 'LOG_R',
  UserCountToIndicator = 'USER_COUNT_INDICATOR',
  GetReceiptHeaderToIndicator = 'RECEIPT_HEADER_INDICATOR_G',

  ManageUser = 'MANAGE_USER',

  DeletePendingReceipt = 'PENDING_RECEIPT_D',

  AdministrationTenantRead = 'ADMINISTRATION_TENANT_R',

  IsAdmin = 'IS_ADMIN',

  reprocessIncompleteReceiptHeaders = 'REPROCESS_INCOMPLETE_RECEIPT_HEADERS',
  GetTenantEmailReceipt = 'TENANT_EMAIL_RECEIPT_R',
  GetTenantSetting = 'TENANT_SETTING_R',
  ReadConceptMaster = 'CONCEPT_MASTER_R',

  ReadFeatureFlag = 'FEATURE_FLAG_R',

  UpdateUserNotificationType = 'USER_NOTIFICATION_TYPE_U',
}

enum InvoiceAction {
  FacturaImportada = 1,
  FacturaIncompleta = 2,
  FacturaParaConformar = 3,
  FacturaProcesada = 4,
  FacturaConformada = 5,
  ImagenAgregada = 6,
  ValletPallet = 7,
  NotaCreditoImportada = 8,
  ConformidadPOD = 9,
  TimbreMesaAyuda = 10,
  LogisticaReversa = 11,
  DetalleRecepción = 12,
  Comentario = 13,
  FacturaReImportada = 14,
  NotaCreditoReImportada = 15,
  FactRechazadaTranportista = 16,
  FactReasignadaTransportista = 17,
  PagoAsociadoFctura = 18,
  ImagenBorrada = 19,
  ValletPalletRemovido = 20,
  ConformidadPODRemovida = 21,
  TimbreMesaAyudaRemovido = 22,
  LogisticaReversaRemovida = 23,
  DetalleRecepcionRemovido = 24,
  FactRequeridaTransportista = 25,
}

enum ConstNumbers {
  negativeOne = -1,
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
  six = 6,
  seven = 7,
  eight = 8,
  nine = 9,
  ten = 10,
  thirty = 30,
  fifty = 50,
  hundred = 100,
  twoHundredFiftyFive = 255,
  thousand = 1000,
}
enum InvoiceFileColumns {
  dt_number = ' Nro Transporte',
  delivery_number = 'Nro. Entrega',
  invoice_number = 'Nro Factura',
  legal_number = 'Numero Legal de Factura',
  billing_date = 'Fe Fat Real',
  receiver = 'Destinatario de Merc',
  submitter = 'Desc. Solicitante',
  quantity = 'Cantidad Facturada',
  net_value = 'Valor Neto Factura',
  sales_organization = 'Org. Ventas',
  address = 'Dirección',
  location = 'Población',
  region_sap_id = 'Región',
  carrier_sap_account = 'Número de cuenta del proveedor',
  payment_condition_sap_id = 'Cond. Pago',
}

enum InvoiceSapValidatedFileColumns {
  dt_number = 'Documento de Transporte',
  delivery_number = 'Entrega',
  invoice_number = 'Factura',
  legal_number = 'Numero NF',
  billing_date = 'Fecha de Factura',
  pod_date = 'Fecha de POD',
  validate_date = 'Fecha de Conforme Provisorio',
  modify_by = 'Modificado por',
}

enum CreditNoteFileColumns {
  order_number = ' Nro Orden',
  client_order_number = 'Nro. OC Cliente',
  request_date = 'Fecha del Pedido',
  nc_sales_organization = 'Org. Ventas',
  invoice_legal_number = 'Numero Legal de Factura',
  nc_billing_date = 'Fe Fat Real',
  nc_quantity = 'Cantidad Facturada',
  nc_net_value = 'Valor Neto Factura',
  nc_submitter = 'Desc. Solicitante',
  nc_receiver = 'Destinatario de Merc',
  nc_invoice_number = 'Nro Factura',
  order_creator = 'Creado por Orden',
  sales_document_class = 'Clase Doc.Ventas',
}

enum ConceptConditionalType {
  etiquetaImpuesto = 1,
  cuitImpuesto = 2,
  etiquetaDescuento = 3,
}

enum ConceptRuleType {
  general = 1,
  particular = 2,
}

enum ConceptType {
  impuesto = 1,
  descuento = 2,
}

enum LogicalOperation {
  AND = 1,
}

enum ConceptRuleStatus {
  activo = 1,
  inactivo = 2,
}

enum FeatureFlags {
  registerPublic = 'registerPublic',
  packsPage = 'packsPage',
  methodsPage = 'methodsPage',
  emailReception = 'emailReception',
  controlsAndValidationsPage = 'controlsAndValidationsPage',
}

/**
 * Numeric Types
 * Name	Storage Size	Range
 * smallint	2 bytes	-32768 to +32767
 * integer	4 bytes	-2147483648 to +2147483647
 * bigint	8 bytes	-9223372036854775808 to 9223372036854775807
 */
enum MaxLengthInputs {
  SystemUser_UserName = 20,
  SystemUser_Email = 100,
  StringInputs = 200,
  NumberInputs = 20,
  NumberInputsMaxInt = 2147483647,
  NumberInputsMaxBigInt = 9223372036854775807,
  CodeSap = 4,
  Region_DeliveryTerm = 2,
}

enum ReceiptStatus {
  Processed = 1,
  Incomplete = 2,
  Integrated = 3,
  Error = 4,
  Finished = 5,
  Loaded = 6,
  ToReview = 7,
  Discarded = 8,
}

enum ReceiptType {
  Factura = 1,
  NotaCredito = 2,
  NotaDebito = 3,
  Recibo = 4,
  Remito = 5,
  OrdenCompra = 6,
  TiqueControladorFiscal = 7,
}

enum IntegrationStatus {
  Active = 1,
  Inactive = 2,
  Contact = 3,
  ComingSoon = 4,
  Connect = 5,
}

enum IntegrationCode {
  finnegans = 'FINNEGANS',
  xubio = 'XUBIO',
  gescom = 'GESCOM',
  jdEdwards = 'JDEDWARDS',
  oracle = 'ORACLENS',
  sap = 'SAP',
  braulioAPI = 'BRAULIOAPI',
  odoo = 'ODOO',
}

enum LocalStorageKeys {
  RoleSelected = 'role_selected',
  TranslationsVersions = 'translation_versions',
  Translations = 'translations',
  TwoFactorValidated = 'two_factor_validated',
  Token = 'token',
  UserId = 'userId',
  LastTenantId = 'LastTenantId',
}
enum SessionStorageKeys {
  FromDateMonitorPage = 'fromDateMonitorPage',
  ToDateMonitorPage = 'toDateMonitorPage',
  InvoiceGrouperId = 'invoiceGrouperId',
  InvoiceselectedFilters = 'InvoiceselectedFilters',
}

enum TwoFactorValidatedValues {
  True = 'true',
  False = 'false',
}

export enum SystemLimits {
  //Se define limite de tamaño de archivos en bytes
  /*
    Recordar que este enum debe corresponder con el de backend.
  */
  MaxSizeOfFiles = 26214400, //25 MB,
  MaxSimultaneousFileUpload = 10,
}

export enum CustomValues {
  MaxRetriesUploadingReceipts = 1,
}

export enum ErrorsStatusCode {
  userIsAlreadyValidatedCode = 65,
  duplicatedFileCode = 121,
  receiptNotToReviewCode = 110,
  fileNotSupportedCode = 46,
}

export enum SettingNames {
  RecoveryPasswordFrom = 'RECOVERY_PASSWORD_FROM',
  RecoveryPasswordSubject = 'RECOVERY_PASSWORD_SUBJECT',
  NodeMailerHost = 'NODEMAILER_HOST',
  NodeMailerPort = 'NODEMAILER_PORT',
  NodeMailerSecure = 'NODEMAILER_SECURE',
  NodeMailerUser = 'NODEMAILER_USER',
  NodeMailerPassword = 'NODEMAILER_PASSWORD',
  NodeMailerAccountType = 'NODEMAILER_ACCOUNT_TYPE',
  UrlSite = 'URL_SITE',
  PasswordRegex = 'PASSWORD_REGEX',
  ProfileRequired = 'PROFILE_REQUIRED',
  SecurityTokenExpiresin = 'SECURITY_TOKEN_EXPIRESIN',
  SecurityRefreshTokenExpiresin = 'SECURITY_REFRESH_TOKEN_EXPIRESIN',
  FirstLogin = 'FIRST_LOGIN',
  LimitMaxSizeFile = 'LIMIT_MAX_SIZE_FILE',
  GAClientID = 'GA_ID',
  ValidateUserByEmail = 'VALIDATE_USER_BY_EMAIL',
  UserPublicRegister = 'USERS_PUBLIC_REGISTER',
  DefaultValueDateFormat = 'DEFAULT_VALUE_DATE_FORMAT',
  DefaultValueDateTimeFormat = 'DEFAULT_VALUE_DATE_TIME_FORMAT',
  DefaultValueLanguage = 'DEFAULT_VALUE_LANGUAGE',
  DefaultValueTimezone = 'DEFAULT_VALUE_TIMEZONE',
  ContactLinkOnPackPage = 'CONTACT_LINK_ON_PACK_PAGE',
  maxAmountOfPendingReceipt = 'MAX_AMOUNT_OF_PENDING_RECEIPT',
  ContactLinkOnIntegrationPage = 'CONTACT_LINK_ON_INTEGRATION_PAGE',
  supportEmail = 'SUPPORT_EMAIL',
  whatsAppNumber = 'WHATSAPP_NUMBER',
  ContactLinkOnHelp = 'CONTACT_LINK_ON_HELP',
  TutorialLinkOnHelp = 'TUTORIAL_LINK_ON_HELP',
  ArrangeMeetingLinkOnHelp = 'ARRANGE_MEETING_LINK_ON_HELP',
}

export enum RegularExpresions {
  TenantName = '^[A-Z0-9_-]+$',
}

enum DescriptionType {
  Equal = 1,
  Contain = 2,
  Near = 3,
}

enum NotificationsItemIds {
  MissDataInDecisionTable = 1,
  MissBillingData = 3,
  ThereIsNotQuota = 4,
  QuotaIsExpired = 5,
}
enum NotificationsItemLinkTo {
  MissDataInDecisionTable = '/app/administration/data_relation',
  MissBillingData = '/app/setting/tenant',
  ThereIsNotQuota = '/app/packs',
  QuotaIsExpired = '/app/packs',
}

enum TargetValues {
  General = 'gral',
  Webinar = 'wbnr',
  Telecom = 'telecom',
}

enum TargetLabels {
  General = 'General',
  Webinar = 'Webinar',
  Telecom = 'Telecom',
}

enum TourSections {
  Menu = 1,
  DecisionTable = 2,
  ReceiptReview = 3,
}

enum RegexPattern {
  MultiEmailPattern = '^([\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})(,[\\w+-.%]+@[\\w.-]+\\.[A-Za-z]{2,4})*$',
}

enum FileDataType {
  PdfBase64 = 'data:application/pdf;base64,',
}

enum FileUnitConvertion {
  InchToPx = 96,
}

export enum DetailItemType {
  product = 1,
  discount = 2,
}

export enum TaxCondition {
  inscriptoIVA = 'IVA Responsable Inscripto',
  exento = 'Exento',
  monotributista = 'Responsable Monotributo',
}

export enum FieldTypes {
  OBJECT = 'object',
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ENUM = 'enum',
}

export enum FieldAttributeTags {
  REVIEWER_VISIBLE = 'reviewer_visible',
  FRONT = 'front',
  CONFIGURATION = 'configuration',
  REQUIRED = 'required',
  DATA_VALIDATION = 'data_validation',
}

export enum ReviewerConfigurationOptionCodes {
  REVIEW_NEVER = 'Nunca revisar',
  REVIEW_ALWAYS = 'Siempre revisar',
  REVIEW_BY_CONFIGUATION = 'Revisar según configuración',
}

enum TenantSettingNames {
  ReceiptCurrency = 'RECEIPT_CURRENCY_CODE',
  ValidateDuplicity = 'VALIDATE_DUPLICITY',
  Languague = 'LANGUAGE',
  Tolerance = 'TOLERANCE',
  DefaultIva = 'DEFAULT_IVA',
  DefaultReceiptType = 'DEFAULT_RECEIPT_TYPE',
  EmailDiscardByFilenameParts = 'EMAIL_DISCARD_BY_FILENAME_PARTS',
  MaxPagePerDocument = 'MAX_PAGE_PER_DOCUMENT',
}

enum ExportReceiptHeadersMapKeys {
  Odoo = 'odoo',
}

export class EnumsValues {
  static readonly Functions = Functions;

  readonly Functions = EnumsValues.Functions;

  static readonly SystemRolesNames = SystemRolesNames;

  readonly SystemRolesNames = EnumsValues.SystemRolesNames;

  static readonly InvoiceFileColumns = InvoiceFileColumns;

  readonly InvoiceFileColumns = EnumsValues.InvoiceFileColumns;

  static readonly ConstNumbers = ConstNumbers;

  readonly ConstNumbers = EnumsValues.ConstNumbers;

  static readonly InvoiceSapValidatedFileColumns =
    InvoiceSapValidatedFileColumns;

  readonly InvoiceSapValidatedFileColumns =
    EnumsValues.InvoiceSapValidatedFileColumns;

  static readonly InvoiceSetStatus = InvoiceSetStatus;

  readonly InvoiceSetStatus = EnumsValues.InvoiceSetStatus;

  static readonly InvoiceStatus = InvoiceStatus;

  readonly InvoiceStatus = EnumsValues.InvoiceStatus;

  static readonly UserRoleSystem = UserRoleSystem;

  readonly UserRoleSystem = EnumsValues.UserRoleSystem;

  static readonly InvoiceAction = InvoiceAction;

  readonly InvoiceAction = EnumsValues.InvoiceAction;

  static readonly MaxLengthInputs = MaxLengthInputs;

  readonly MaxLengthInputs = EnumsValues.MaxLengthInputs;

  static readonly CreditNoteFileColumns = CreditNoteFileColumns;

  readonly CreditNoteFileColumns = EnumsValues.CreditNoteFileColumns;

  static readonly ReturnFileType = ReturnFileType;

  readonly ReturnFileType = EnumsValues.ReturnFileType;

  static readonly ReturnOrderStatus = ReturnOrderStatus;

  readonly ReturnOrderStatus = EnumsValues.ReturnOrderStatus;

  static readonly ReceiptStatus = ReceiptStatus;

  readonly ReceiptStatus = EnumsValues.ReceiptStatus;

  static readonly ReceiptType = ReceiptType;

  readonly ReceiptType = EnumsValues.ReceiptType;

  static readonly IntegrationStatus = IntegrationStatus;

  readonly IntegrationStatus = EnumsValues.IntegrationStatus;

  static readonly IntegrationCode = IntegrationCode;

  readonly IntegrationCode = EnumsValues.IntegrationCode;

  static readonly LocalStorageKeys = LocalStorageKeys;

  readonly LocalStorageKeys = EnumsValues.LocalStorageKeys;

  static readonly SessionStorageKeys = SessionStorageKeys;

  readonly SessionStorageKeys = EnumsValues.SessionStorageKeys;

  static readonly TwoFactorValidatedValues = TwoFactorValidatedValues;

  readonly TwoFactorValidatedValues = EnumsValues.TwoFactorValidatedValues;

  static readonly SettingNames = SettingNames;

  readonly SettingNames = EnumsValues.SettingNames;

  static readonly SystemLimits = SystemLimits;

  readonly SystemLimits = EnumsValues.SystemLimits;

  static readonly CustomValues = CustomValues;

  readonly CustomValues = EnumsValues.CustomValues;

  static readonly ErrorsStatusCode = ErrorsStatusCode;

  readonly ErrorsStatusCode = EnumsValues.ErrorsStatusCode;

  static readonly RegularExpressions = RegularExpresions;

  readonly RgularExpressions = EnumsValues.RegularExpressions;

  static readonly DescriptionType = DescriptionType;

  readonly DescriptionType = EnumsValues.DescriptionType;

  static readonly NotificationsItemIds = NotificationsItemIds;

  readonly NotificationsItemIds = EnumsValues.NotificationsItemIds;

  static readonly NotificationsItemLinkTo = NotificationsItemLinkTo;

  readonly NotificationsItemLinkTo = EnumsValues.NotificationsItemLinkTo;

  static readonly TargetValues = TargetValues;

  readonly TargetValues = EnumsValues.TargetValues;

  static readonly TargetLabels = TargetLabels;

  readonly TargetLabels = EnumsValues.TargetLabels;

  static readonly TourSections = TourSections;

  readonly TourSections = EnumsValues.TourSections;

  static readonly RegexPattern = RegexPattern;

  readonly RegexPattern = EnumsValues.RegexPattern;

  static readonly FileDataType = FileDataType;

  readonly FileDataType = EnumsValues.FileDataType;

  static readonly FileUnitConvertion = FileUnitConvertion;

  readonly FileUnitConvertion = EnumsValues.FileUnitConvertion;

  static readonly ConceptConditionalType = ConceptConditionalType;

  readonly ConceptConditionalType = EnumsValues.ConceptConditionalType;

  static readonly ConceptRuleType = ConceptRuleType;

  readonly ConceptRuleType = EnumsValues.ConceptRuleType;

  static readonly ConceptType = ConceptType;

  readonly ConceptType = EnumsValues.ConceptType;

  static readonly LogicalOperation = LogicalOperation;

  readonly LogicalOperation = EnumsValues.LogicalOperation;

  static readonly ConceptRuleStatus = ConceptRuleStatus;

  readonly ConceptRuleStatus = EnumsValues.ConceptRuleStatus;

  static readonly FeatureFlags = FeatureFlags;

  readonly FeatureFlags = EnumsValues.FeatureFlags;

  static readonly TaxCondition = TaxCondition;

  readonly TaxCondition = EnumsValues.TaxCondition;

  static readonly FieldTypes = FieldTypes;

  readonly FieldTypes = EnumsValues.FieldTypes;

  static readonly DetailItemType = DetailItemType;

  readonly DetailItemType = EnumsValues.DetailItemType;

  static readonly FieldAttributeTags = FieldAttributeTags;

  readonly FieldAttributeTags = EnumsValues.FieldAttributeTags;

  static readonly ReviewerConfigurationOptionCodes =
    ReviewerConfigurationOptionCodes;

  readonly ReviewerConfigurationOptionCodes =
    EnumsValues.ReviewerConfigurationOptionCodes;

  static readonly TenantSettingNames = TenantSettingNames;

  readonly TenantSettingNames = EnumsValues.TenantSettingNames;

  static readonly ExportReceiptHeadersMapKeys = ExportReceiptHeadersMapKeys;

  readonly ExportReceiptHeadersMapKeys =
    EnumsValues.ExportReceiptHeadersMapKeys;

  constructor(init?: Partial<EnumsValues>) {
    Object.assign(this, init);
  }
}
